<template>
  <v-container>
    <div class="pa-2">
      <h1 class="primary--text">Modèles de document</h1>
      <v-simple-table dense v-if="!loading">
        <template v-slot:default>
          <tbody  v-for="modeldoc in modeldocs"
              :key="modeldoc.id">
          <tr>
            <th class="text-left">
              Nom du modèle
            </th>
            <th class="text-left">
              ID du document
            </th>
            <th class="text-left">
              Page de la signature
            </th>
            <th class="text-left">
              Position de la signature
            </th>
          </tr>
          <tr>
            <td>{{ modeldoc.name }}</td>
            <td><a TARGET="_blank" :href="'https://docs.google.com/document/d/' + modeldoc.model_id + '/edit'">
              {{ modeldoc.name }}
            </a></td>
            <td>{{modeldoc.signature_page}}</td>
            <td>{{modeldoc.signature_position}}</td>
            <td>
              <ModelDocForm :modelDoc="modeldoc" @end="loadModelDocs"/>
              <v-btn @click="deleteModelDoc(modeldoc)" class="white--text" color="red">
                <v-icon>mdi-trash-can</v-icon>
                Supprimer
              </v-btn>
            </td>
          </tr>
          <tr style="max-height: 30px">
            <td colspan="5">
              <span class="font-weight-bold">Signatures</span>
            </td>
          </tr>
          <tr style="min-height: 10px; max-height: 30px" v-for="signature in modeldoc.signatures" :key="signature.id">
            <td colspan="5">
              <div  class="d-flex justify-space-between">
                <span class="mt-2">{{ signature.signe_par }} - Page : {{ signature.signature_page }} - Position : {{ signature.signature_position }}</span>
                <div>
                  <v-btn icon color="red" @click="deleteSignature(signature.id)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5"><SignatureForm @end="loadModelDocs()" :model_doc="modeldoc"></SignatureForm></td>
          </tr>
          <tr style="max-height: 30px">
            <td colspan="5">
              <span class="font-weight-bold">Champs uniques</span>
            </td>
          </tr>
          <tr style="min-height: 10px; max-height: 30px" v-for="custom_field in modeldoc.custom_fields" :key="custom_field.id">
            <td colspan="5">
              <div  class="d-flex justify-space-between">
                  <span class="mt-2">{{ custom_field.name }} - Code : {{ custom_field.code}}</span>
                <div>
                  <v-btn icon color="red" @click="deleteCustomField(custom_field.id)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5"><CustomFieldForm @end="loadModelDocs()" :model_doc="modeldoc"></CustomFieldForm></td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <td>
              <v-text-field label="Nom du modèle" v-model="modelDocModel.name"></v-text-field>
            </td>
            <td>
              <v-text-field label="ID du document" v-model="modelDocModel.model_id"></v-text-field>
            </td>
            <td>
              <v-text-field label="Page de la signature" v-model="modelDocModel.signature_page"></v-text-field>
            </td>
            <td>
              <v-text-field label="Position de la signature" v-model="modelDocModel.signature_position"></v-text-field>
            </td>

            <td>
              <v-btn color="primary" @click="createModelDocs">Créer</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row v-else justify="center" align-content="center">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ModelDocForm from "@/components/modeldocs/ModelDocForm";
import SignatureForm from "@/components/signature/SignatureForm.vue";
import {SignatureRepository} from "@/repository/signature";
import {CustomFieldRepository} from "@/repository/custom_field";
import CustomFieldForm from "@/components/custom_fields/CustomFieldForm.vue";

export default {
  components: {CustomFieldForm, SignatureForm, ModelDocForm},
  async mounted() {
    await this.loadModelDocs();
  },
  data() {
    return {
      modelDocModel: {
        name: "",
        model_id: "",
        signature_page: "",
        signature_position: "",
      },
      signature_repository: new SignatureRepository(),
      custom_field_repository: new CustomFieldRepository(),
      loading: false,
      dialog: false,
    }
  },
  computed: {
    modeldocs() {
      return this.$store.getters["modeldocs/getModelDocs"];
    }
  },
  methods: {
    async createModelDocs() {
      if (this.modelDocModel.model_id !== "" && this.modelDocModel.name !== "") {
        await this.$store.dispatch("modeldocs/postModelDoc", this.modelDocModel);
        this.modelDocModel = {
          name: "",
          model_id: "",
          signature_page: "",
          signature_position: "",
        }
      }
      await this.loadModelDocs();
    },
    async loadModelDocs() {
      this.loading = true;
      await this.$store.dispatch("modeldocs/fetchModelDocs");
      this.loading = false;
    },
    async deleteModelDoc(model_doc) {
      console.log(model_doc);
      this.loading = true;
      await this.$store.dispatch("modeldocs/deleteModelDoc", model_doc);
      await this.$store.dispatch("modeldocs/fetchModelDocs");
      await this.$store.dispatch("annonce/annonceSuccess", "Modèle de document supprimé.")
      this.loading = false;
    },
    async deleteSignature(id) {
      let result = await this.signature_repository.delete({id: id});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Signature supprimée.");
        this.loadModelDocs();
      }
    },
    async deleteCustomField(id) {
      let result = await this.custom_field_repository.delete({id: id});
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Champ unique supprimée.");
        this.loadModelDocs();
      }
    }
  }

}
</script>

