<template>
  <v-dialog max-width="800" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">Ajouter un champ unique</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Ajouter un champ unique</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="customFieldForm">
          <v-text-field :rules="[notNullNorEmpty]" label="Nom" v-model="custom_field.name"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Code" v-model="custom_field.code"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="createCustomField" :disabled="creating">Ajouter</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="creating"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
import {CustomFieldRepository} from "@/repository/custom_field";

export default {
  name: 'CustomFieldForm',
  mixins: [ValidationRulesMixin],
  props: {
    model_doc: Object,
  },
  data() {
    return {
      dialog: false,
      creating: false,
      repository: new CustomFieldRepository(),
      custom_field: {
        "code": "",
        "name": "",
        "model_doc": this.model_doc.id,
      }
    }
  },
  methods: {
    async createCustomField() {
      if (this.$refs.customFieldForm.validate()) {
        this.creating = true;
        try {
          await this.repository.post(this.custom_field);
          this.$store.dispatch("annonce/annonceSuccess", "Champ unique créé.");
          this.dialog = false;
          this.$emit("end");
        } catch (e) {
          this.$store.dispatch("annonce/annonceSuccess", "Une erreur est survenue");
        }

        this.creating = false;
      }
    }
  },
  computed: {
  }

}
</script>