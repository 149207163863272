<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mx-2" v-on="on" v-bind="attrs" color="primary">
        <v-icon>mdi-pencil</v-icon>
        Modifier
      </v-btn>
    </template>
    <v-card width="600">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le modèle de document</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-text-field v-model="modelDocModel.name" label="Nom du modèle"></v-text-field>
          <v-text-field v-model="modelDocModel.model_id" label="ID du document"></v-text-field>
          <v-text-field v-model="modelDocModel.signature_page" label="Page de la signature"></v-text-field>
          <v-text-field v-model="modelDocModel.signature_position" label="Position de la signature"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
        <v-btn :color="loading ? 'grey' : 'primary'" :disabled="loading" @click="patchModelDoc">
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ModelDocForm',
  props: {
    modelDoc: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    let modelDocModel = JSON.parse(JSON.stringify(this.modelDoc));
    return {
      modelDocModel,
      loading: false,
      dialog: false,
    };
  },
  methods: {
    async patchModelDoc() {
      this.loading = true;
      await this.$store.dispatch("modeldocs/patchModelDoc", this.modelDocModel);
      this.loading = false;
      this.dialog = false;
      this.$emit("end");
    }
  },
}
</script>
