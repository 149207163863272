<template>
  <v-dialog max-width="800" v-model="signatureDialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">Ajouter une signature</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Ajouter une signature</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="signatureForm">
          <v-text-field :rules="[notNullNorEmpty]" label="Signé par" v-model="signature.signe_par"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Page de signature" v-model="signature.signature_page" type="number"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Position de signature" v-model="signature.signature_position"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="createSignature" :disabled="creating">Ajouter</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="creating"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {SignatureRepository} from "@/repository/signature";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'SignatureForm',
  mixins: [ValidationRulesMixin],
  props: {
    model_doc: Object,
  },
  data() {
    return {
      signatureDialog: false,
      creating: false,
      repository: new SignatureRepository(),
      signature: {
        "signe_par": "",
        "signature_page": null,
        "signature_position": "",
        "model_doc": this.model_doc.id,
      }
    }
  },
  methods: {
    async createSignature() {
      if (this.$refs.signatureForm.validate()) {
        this.creating = true;
        try {
          await this.repository.post(this.signature);
          this.$store.dispatch("annonce/annonceSuccess", "Signature créée.");
          this.signatureDialog = false;
          this.$emit("end");
        } catch (e) {
          this.$store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }

        this.creating = false;
      }
    }
  },
  computed: {
    signe_par_select() {
      return [
        {name: "Opérateur"},
        {name: "Bénéficiaire"},
      ]
    }
  }

}
</script>